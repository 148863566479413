<template>
  <div class="mb-5">
    <div v-for="s in statusOptions" :key="s">
      <div v-if="s == status">
        <b-btn :variant=getVariant(s) v-bind="$attrs" class="d-block" size="sm" style="width: 90%;" :disabled="true">
          <div class="d-inline-block">
            <!-- <span class="d-inline-block my-2"><i :class="getStatusIcon(s)" class="status-badge"></i></span> -->
            <span class="d-inline-bock pl-2 my-2 line-height-sm font-weight-bolder text-capitalize"
              :style="{ wordBreak: 'break-word' }">{{
                s }}</span>
          </div>
        </b-btn>

      </div>
      <div v-else>
        <b-btn :variant="getVariant(s)" v-bind="$attrs" class="d-block" size="sm" style="width: 90%;"
          @click="onBtnClick(s)">
          <div class="d-inline-block ">
            <!-- <span class="d-inline-block my-2"><i :class="getStatusIcon(s)" class="status-badge"></i></span> -->
            <span class="d-inline-bock pl-2 my-2 line-height-sm font-weight-bolder text-capitalize"
              :style="{ wordBreak: 'break-word' }">{{
                s }}</span>
          </div>
        </b-btn>
      </div>

    </div>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
export default {
  name: "SecondmentStatusSwal",

  props: {
    status: {
      type: String,
      required: true,
    },
    statusOptions: {
      type: Object,
      required: true
    }
  },

  computed: {
  },
  methods: {
    getVariant(status) {
      switch (status) {
        case this.statusOptions.CONFIRMED:
          return "light-primary";
        case this.statusOptions.CANCELLED:
          return "light-danger";
        case this.statusOptions.EDITED:
          return "light-info";
        case this.statusOptions.DRAFT:
          return "light-dark";
        case this.statusOptions.PROCESSING:
          return "light-warning";
        case this.statusOptions.COMPLETED:
          return "light-success";
        default:
          return "";
      }
    },
    getStatusIcon(s) {
      switch (s) {
        case this.statusOptions.CONFIRMED:
          return icons.check;
        case this.statusOptions.CANCELLED:
          return icons.close;
        case this.statusOptions.EDITED:
          return icons.edit;
        case this.statusOptions.DRAFT:
          return icons.saveDraft;
        case this.statusOptions.PROCESSING:
          return icons.sync;
        case this.statusOptions.COMPLETED:
          return icons.clipboard_check;
        default:
          return icons.circleFilled;
      }
    },

    onBtnClick(status) {
      this.$emit('statusSubmitted', status, this.getVariant(status), this.getStatusIcon(status));
    }
  }
};
</script>

<style></style>
