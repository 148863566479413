<template>
  <div>
    <div v-if="isApStaff">
      <b-btn :id="uid" :variant="variant" v-bind="$attrs" class="d-block" size="sm" @click="onBtnClick">
        <div class="d-inline-block my-n2">
          <span class="d-inline-bock pl-2 my-2 line-height-sm font-weight-bolder text-capitalize"
            :style="{ wordBreak: 'break-word' }">{{
      status }}</span>
          <span v-if="past" class="ml-1">({{ $t('Past') }})</span>
          <span v-if="started" class="ml-1">({{ $t('Started') }})</span>
        </div>
      </b-btn>
    </div>
    <div v-else>
      <div v-if="status == 'draft'" class="d-inline-block my-n2">
        <b-btn :id="uid" :variant="variant" v-bind="$attrs" class="d-block" size="sm" @click="onBtnClick">
          <div class="d-inline-block my-n2">
            <span class="d-inline-bock line-height-sm font-weight-bolder text-capitalize"
              :style="{ wordBreak: 'break-word' }">
              {{ status }}
            </span>
          </div>
        </b-btn>

      </div>
      <div v-else class="d-inline-block my-n2">
        <secondment-status-badge size="sm" :status="status" label-only :past="past" :started="started"></secondment-status-badge>
      </div>
    </div>
    <b-tooltip :target="uid" triggers="hover" placement="left" noninteractive>
      <strong v-if="iconOnly">{{ status }}</strong>
      <div v-if="status == 'draft' || isApStaff" class="d-flex flex-column">
        <span>{{ $t("Click to change the assignment status") }}</span>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
import { STATUS_TYPE } from "@/core/abstractions/secondment";
import SecondmentStatusSwal from "@/view/components/buttons/SecondmentStatusSwal.vue"
import SecondmentStatusBadge from "@/view/components/badges/SecondmentStatusBadge.vue";
import icons from "@/core/config/icons";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import Vue from "vue";
import SecondmentService from "@/core/services/secondment/secondment.service";


export default {
  components: {
    SecondmentStatusBadge,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    past: {
      type: Boolean,
      default: false,
    },
    started: {
      type: Boolean,
      default: false,
    },
    secondment: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters("user", ["isApStaff"]),
    uid() {
      return `status-chip-${this._uid}`;
    },
    STATUS_TYPE() {
      return STATUS_TYPE;
    },
    variant() {
      switch (this.status) {
        case STATUS_TYPE.CONFIRMED:
          return "light-primary";
        case STATUS_TYPE.CANCELLED:
          return "light-danger";
        case STATUS_TYPE.EDITED:
          return "light-info";
        case STATUS_TYPE.DRAFT:
          return "light-dark";
        case STATUS_TYPE.PROCESSING:
          return "light-warning";
        case STATUS_TYPE.COMPLETED:
          return "light-success";
        default:
          return "";
      }
    },
    statusIcon() {
      switch (this.status) {
        case STATUS_TYPE.CONFIRMED:
          return icons.check;
        case STATUS_TYPE.CANCELLED:
          return icons.close;
        case STATUS_TYPE.EDITED:
          return icons.edit;
        case STATUS_TYPE.DRAFT:
          return icons.saveDraft;
        case STATUS_TYPE.PROCESSING:
          return icons.sync;
        case STATUS_TYPE.COMPLETED:
          return icons.clipboard_check;
        default:
          return icons.circleFilled;
      }
    },
  },

  methods: {
    nextStatusOk() {
      switch (this.status) {
        case STATUS_TYPE.DRAFT:
          return STATUS_TYPE.CONFIRMED;
        case STATUS_TYPE.CANCELLED:
          return STATUS_TYPE.CANCELLED;
        default:
          return "";
      }
    },


    async onBtnClick() {
      if (!this.isApStaff) {
        // call check date start to see if the secondment is to be set as past from secondmentService checkDateStart
        // const checkDateStart = await SecondmentService.checkDateStart(this.secondment.country, this.secondment.date_start);
        const {
          data: { status, elaboration_time, min_start_date },
        } = await SecondmentService.checkDateStart(
          this.secondment.country,
          this.secondment.date_start,
          this.secondment.date_end
        );
        const past = status === "error";
        const started = status === "warning";
        let text = this.$t("Please select the secondment status")
        let icon = "info"
        const nextStatusOk = this.nextStatusOk();
        let status_button_label = nextStatusOk
        if (!nextStatusOk) return;
        if (past) {
          text = this.$t("PAST_SECONDMENT_WARNING_TEXT");
          text += "<br/>" + this.$t("PAST_SECONDMENT_WARNING_ELABORATION_TIME", { elaboration_time: elaboration_time, min_start_date: min_start_date });
          text += "<br/>" + this.$t("Please select the secondment status")
          icon = "warning"
          status_button_label += " (" + this.$t("past") + ")"
        }
        if (started) {
          text = this.$t("STARTED_SECONDMENT_WARNING_TEXT");
          text += "<br/>" + this.$t("PAST_SECONDMENT_WARNING_ELABORATION_TIME", { elaboration_time: elaboration_time, min_start_date: min_start_date });
          text += "<br/>" + this.$t("Please select the secondment status")
          icon = "warning"
          status_button_label += " (" + this.$t("started") + ")"
        }
        
        
        

        const { isConfirmed, isDenied } = await Swal.fire({
          title: this.$t("Changing status"),
          html: text,
          icon: icon,
          showConfirmButton: true,
          showDenyButton: true,
          showCloseButton: true,
          closeButtonHtml: "<i class='fas fa-times p-0'></i>",
          confirmButtonText: `<i class="fa fa-check text-light"></i> ${status_button_label}`,
          denyButtonText: `<i class="fa fa-trash text-light"></i> ${STATUS_TYPE.CANCELLED}`,
          confirmButtonClass: ["btn", "btn-primary"],
          denyButtonClass: ["btn", "btn-danger"],
          customClass: {
            closeButton: ["btn", "btn-danger", "m-1"],
          },
        });

        if (isConfirmed) {
          this.$emit("update-status", nextStatusOk);
          return;
        }

        if (isDenied) {
          this.$emit("update-status", STATUS_TYPE.CANCELLED);
          return;
        }
      } else {
        const params = {
          status: this.status,
          statusOptions: STATUS_TYPE
        }
        const mixin = Swal.mixin({
          width: 400,
          html: '<div id="secondment-status-swal"></div>',
          onOpen: () => {
            const statusSmall = document.createElement('p');
            statusSmall.textContent = this.$t(`To change the status, click one of the buttons`);
            statusSmall.style.fontSize = "10px";
            statusSmall.style.marginTop = '10px';
            statusSmall.style.marginBottom = '40px';
            Swal.getContent().appendChild(statusSmall);
            new Vue({
              el: '#secondment-status-swal',
              render: (h) => h(SecondmentStatusSwal, {
                props: {
                  status: params.status,
                  statusOptions: params.statusOptions
                },
                on: {

                  statusSubmitted: (newStatus, newVariant) => {
                    Swal.fire({
                      title: 'Selected Status',
                      html: `
                      <div class="d-flex align-items-start  flex-column">
                      <div class="p2">
                        <i>` + this.$t("Current status") + `  :</i>
                        <div type="button" class="btn btn-${this.variant} btn-sm overflow-hidden ml-6">
                          <span class="d-inline-bock pl-2  line-height-sm font-weight-bolder text-capitalize">${this.status}</span>
                        </div>
                      </div>
                      <div class="p2">
                        <i> ` + this.$t("New status") + `  :</i>
                        <div type="button" class="btn btn-${newVariant} btn-sm overflow-hidden ml-15">
                          <span class="d-inline-bock pl-2 my-2 line-height-sm font-weight-bolder text-capitalize">${newStatus}</span>
                        </div>
                      </div>
                      </div>
                            `,
                      showCancelButton: true,
                      confirmButtonText: this.$t('Ok'),
                      cancelButtonText: this.$t('Cancel'),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.$emit("update-status", newStatus);
                        return;
                      } else if (result.isDismissed) {
                        return;
                      }
                    });
                  }
                }
              }),
            });
          },

        });

        await mixin.fire({
          title: this.$t('Change assignment status'),
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
          closeButtonHtml: "<i class='fas fa-times p-0'></i>",
          customClass: {
            closeButton: ["btn", "btn-danger", "m-1"],
          },
        });

      }
    },
  },
};
</script>

<style>
.status-badge {
  font-size: 1rem !important;
  padding: 0 !important;
}

.swal2-popup {
  padding: 0;
}
</style>
