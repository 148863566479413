var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isApStaff ? _c('div', [_c('b-btn', _vm._b({
    staticClass: "d-block",
    attrs: {
      "id": _vm.uid,
      "variant": _vm.variant,
      "size": "sm"
    },
    on: {
      "click": _vm.onBtnClick
    }
  }, 'b-btn', _vm.$attrs, false), [_c('div', {
    staticClass: "d-inline-block my-n2"
  }, [_c('span', {
    staticClass: "d-inline-bock pl-2 my-2 line-height-sm font-weight-bolder text-capitalize",
    style: {
      wordBreak: 'break-word'
    }
  }, [_vm._v(_vm._s(_vm.status))]), _vm.past ? _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("(" + _vm._s(_vm.$t('Past')) + ")")]) : _vm._e(), _vm.started ? _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("(" + _vm._s(_vm.$t('Started')) + ")")]) : _vm._e()])])], 1) : _c('div', [_vm.status == 'draft' ? _c('div', {
    staticClass: "d-inline-block my-n2"
  }, [_c('b-btn', _vm._b({
    staticClass: "d-block",
    attrs: {
      "id": _vm.uid,
      "variant": _vm.variant,
      "size": "sm"
    },
    on: {
      "click": _vm.onBtnClick
    }
  }, 'b-btn', _vm.$attrs, false), [_c('div', {
    staticClass: "d-inline-block my-n2"
  }, [_c('span', {
    staticClass: "d-inline-bock line-height-sm font-weight-bolder text-capitalize",
    style: {
      wordBreak: 'break-word'
    }
  }, [_vm._v(" " + _vm._s(_vm.status) + " ")])])])], 1) : _c('div', {
    staticClass: "d-inline-block my-n2"
  }, [_c('secondment-status-badge', {
    attrs: {
      "size": "sm",
      "status": _vm.status,
      "label-only": "",
      "past": _vm.past,
      "started": _vm.started
    }
  })], 1)]), _c('b-tooltip', {
    attrs: {
      "target": _vm.uid,
      "triggers": "hover",
      "placement": "left",
      "noninteractive": ""
    }
  }, [_vm.iconOnly ? _c('strong', [_vm._v(_vm._s(_vm.status))]) : _vm._e(), _vm.status == 'draft' || _vm.isApStaff ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Click to change the assignment status")))])]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }