var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0 d-flex"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Assignments")) + " ")])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-input', {
    staticClass: "min-w-300px mr-0 mb-0",
    attrs: {
      "placeholder": _vm.$t('Search'),
      "debounce": 300
    },
    model: {
      value: _vm.searchFilter,
      callback: function callback($$v) {
        _vm.searchFilter = $$v;
      },
      expression: "searchFilter"
    }
  }), _c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'create-secondment'
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Tools/Compass.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Create")) + " ")])])], 1)]), _c('div', {
    staticClass: "text-capitalize card-body detail"
  }, [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "per-page-options": _vm.perPageOptions,
      "filter": _vm.searchFilter,
      "options": _vm.tableOptions,
      "total": _vm.total
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.country",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "font-weight-normal"
        }, [_c('country-flag', {
          attrs: {
            "country-iso": item.country
          }
        })], 1)];
      }
    }, {
      key: "cell.created",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(item.created ? _vm.DateService.format(item.created, _vm.dateFormat) : ""))])];
      }
    }, {
      key: "cell.date_start",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(" " + _vm._s(item.date_start ? _vm.DateService.format(item.date_start, _vm.dateFormat) : "") + " ")])];
      }
    }, {
      key: "cell.date_end",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(" " + _vm._s(item.date_end ? _vm.DateService.format(item.date_end, _vm.dateFormat) : "") + " ")])];
      }
    }, {
      key: "cell.client",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.clients.length ? _c('div', {
          staticClass: "font-weight-bold"
        }, _vm._l(item.clients, function (client) {
          return _c('div', {
            key: client.id
          }, [_vm._v(_vm._s(client.name))]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell.employees",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.employees.length ? _c('div', {
          staticClass: "font-weight-normal m-n1"
        }, _vm._l(item.employees.slice(0, 2), function (employee) {
          return _c('div', {
            key: employee.id,
            staticClass: "m-1"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('avatar', {
            staticClass: "flex-shrink-0",
            attrs: {
              "rounded": "",
              "avatar-image": employee.avatar,
              "avatar-text": "",
              "size": "20px"
            }
          }), _c('span', {
            staticClass: "ml-1 w-100"
          }, [_vm._v(_vm._s(employee.name))])], 1)]);
        }), 0) : _vm._e(), item.employees.length > 2 ? _c('div', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "id": item.id,
            "dropright": "",
            "text": "Show More",
            "size": "sm",
            "variant": "text",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-right w-100"
              }, [_c('span', {
                staticClass: "employees-dropdown"
              }, [_vm._v("Show More")])])];
            },
            proxy: true
          }], null, true)
        }, _vm._l(item.employees.slice(2), function (employee) {
          return _c('b-dropdown-item', {
            key: employee.id,
            staticClass: "m-1"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('avatar', {
            staticClass: "flex-shrink-0",
            attrs: {
              "rounded": "",
              "avatar-image": employee.avatar,
              "avatar-text": "",
              "size": "20px"
            }
          }), _c('span', {
            staticClass: "ml-1 w-100"
          }, [_vm._v(_vm._s(employee.name))])], 1)]);
        }), 1)], 1) : _vm._e()];
      }
    }, {
      key: "cell.contractors",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.contractors.length ? _c('div', {
          staticClass: "font-weight-normal"
        }, _vm._l(item.contractors, function (contractor) {
          return _c('div', {
            key: contractor.id
          }, [_c('span'), _vm._v(" " + _vm._s(contractor.name))]);
        }), 0) : _c('div')];
      }
    }, {
      key: "cell.days",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "font-weight-normal"
        }, [_vm._v(_vm._s(_vm.calculateDays(item.date_start, item.date_end)))])];
      }
    }, {
      key: "cell.status",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('secondment-status-button', {
          attrs: {
            "size": "sm",
            "block": "",
            "icon-only": "",
            "status": item.status,
            "past": item.past,
            "started": item.started,
            "secondment": item
          },
          on: {
            "update-status": function updateStatus($event) {
              return _vm.onSecondmentStatusBtnClick($event, item.id);
            }
          }
        })];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('View Details')
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "title": _vm.$t('Delete'),
            "disabled": item.status != 'draft'
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }