<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0 d-flex">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Assignments") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="min-w-300px mr-0 mb-0" :debounce="300">
        </euro-input>
        <router-link :to="{ name: 'create-secondment' }" class="btn btn-light-primary font-weight-bolder">
          <div class="d-flex">
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
            </span>
            {{ $t("Create") }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="text-capitalize card-body detail">
      <datatable ref="datatableRef" :table-props="tableProps" :per-page-options="perPageOptions" :filter="searchFilter"
        :options.sync="tableOptions" :total="total">
        <template #[`cell.country`]="{ item }">
          <div class="font-weight-normal">
            <country-flag :country-iso="item.country"></country-flag>
          </div>
        </template>

        <template #[`cell.created`]="{ item }">
          <div class="font-weight-bolder text-muted">{{ item.created ? DateService.format(item.created, dateFormat) : ""
            }}</div>
        </template>

        <template #[`cell.date_start`]="{ item }">
          <div class="font-weight-bolder text-muted">
            {{ item.date_start ? DateService.format(item.date_start, dateFormat) : "" }}
          </div>
        </template>

        <template #[`cell.date_end`]="{ item }">
          <div class="font-weight-bolder text-muted">
            {{ item.date_end ? DateService.format(item.date_end, dateFormat) : "" }}
          </div>
        </template>

        <template #[`cell.client`]="{ item }">
          <div v-if="item.clients.length" class="font-weight-bold">
            <div v-for="client in item.clients" :key="client.id">{{ client.name }}</div>
          </div>
        </template>

        <template #[`cell.employees`]="{ item }">
          <div v-if="item.employees.length" class="font-weight-normal m-n1">
            <div v-for="employee in item.employees.slice(0, 2)" :key="employee.id" class="m-1">
              <div class="d-flex align-items-center ">
                <avatar rounded :avatar-image="employee.avatar" avatar-text="" class="flex-shrink-0" size="20px">
                </avatar>
                <span class="ml-1 w-100">{{ employee.name }}</span>
              </div>
            </div>
          </div>
          <div v-if="item.employees.length > 2" class="text-center">
            <b-dropdown :id="item.id" dropright text="Show More" size="sm" variant="text" no-caret>
              <template #button-content>
                <div class="text-right w-100">
                  <span class="employees-dropdown">Show More</span>
                </div>
              </template>
              <b-dropdown-item v-for="employee in item.employees.slice(2)" :key="employee.id" class="m-1">
                <div class="d-flex align-items-center ">
                  <avatar rounded :avatar-image="employee.avatar" avatar-text="" class="flex-shrink-0" size="20px">
                  </avatar>
                  <span class="ml-1 w-100">{{ employee.name }}</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <template #[`cell.contractors`]="{ item }">
          <div v-if="item.contractors.length" class="font-weight-normal">
            <div v-for="contractor in item.contractors" :key="contractor.id"><span></span> {{ contractor.name }}</div>
          </div>
          <div v-else></div>
        </template>

        <template #[`cell.days`]="{ item }">
          <div class="font-weight-normal">{{ calculateDays(item.date_start, item.date_end) }}</div>
        </template>

        <template #[`cell.status`]="{ item }">
          <secondment-status-button size="sm" block icon-only :status="item.status" :past="item.past" :started="item.started" :secondment="item"
            @update-status="$event => onSecondmentStatusBtnClick($event, item.id)"></secondment-status-button>
        </template>

        <template #[`cell.actions`]="{ item }">
          <div class="d-flex">
            <b-button v-b-tooltip.hover :title="$t('View Details')"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="edit(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
            </b-button>
            <b-button v-b-tooltip.hover :title="$t('Delete')" class="btn btn-icon btn-light btn-hover-primary btn-sm"
              :disabled="item.status != 'draft'" @click="remove(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/General/Trash.svg" />
              </span>
            </b-button>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CountryFlag from "@/view/components/CountryFlag.vue";
import Datatable from "@/view/components/tables/Datatable.vue";
import SecondmentService from "@/core/services/secondment/secondment.service";
import { updateSecondmentStatus } from "@/core/abstractions/secondment";
import DateService from "@/core/services/date.service";
import SecondmentStatusButton from "@/view/components/buttons/SecondmentStatusButton.vue";
import { mapGetters } from "vuex";
import { backendErrorSwal, deleteSwal } from "@/core/helpers/swal";
import Avatar from "@/view/components/avatars/Avatar.vue";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import { successToast } from '@/core/helpers';



export default {
  components: {
    Datatable,
    CountryFlag,
    SecondmentStatusButton,
    Avatar,
  },

  props: {
    adminView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      DateService: DateService,
      fields: [
        { label: this.$t("Country"), key: "country", sortable: true },
        { label: this.$t("Client"), key: "client" },
        { label: this.$t("Employees"), key: "employees" },
        { label: this.$t("From Date"), key: "date_start", sortable: true, class: ["font-weight-bolder"] },
        { label: this.$t("To Date"), key: "date_end", sortable: true, class: ["font-weight-bolder"] },
        { label: this.$t("Days"), key: "days" },
        { label: this.$t("Created"), key: "created", sortable: true },
        { label: this.$t("Status"), key: "status", sortable: true },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: "",
      total: 0,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),
    ...mapGetters("constants", ["countriesMap"]),

    tableProps() {
      return {
        items: this.loadAssignments,
        fields: this.fields,
        filter: this.searchFilter,
      };
    },
  },

  beforeMount() {
    if (this.adminView) {
      this.fields.unshift({ label: this.$t("Company"), key: "company_name", sortable: false });
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignment") },
      { title: this.$t("Manage") },
    ]);
  },

  methods: {
    getCountryName(iso) {
      return this.countriesMap[iso]?.name;
    },
    edit(item) {
      this.$router.push({
        name: "detail-secondment",
        params: { secondmentId: item.id },
      });
    },

    remove(item) {
      deleteSwal().then(async res => {
        if (res.isConfirmed) {
          try {
            await SecondmentService.delete(item.id);
            this.$refs.datatableRef.refresh();
          } catch (err) {
            backendErrorSwal(err, err?.response?.data?.detail);
          }
        }
      });
    },

    refresh() {
      this.$refs.datatableRef.refresh();
    },

    onSecondmentStatusBtnClick(newStatus, secondmentId) {
      updateSecondmentStatus(secondmentId, newStatus, false)
        .then(() => {
          successToast(this.$t("Assignment status updated!"));
          this.refresh();
        })
        .catch(err => {
          backendErrorSwal(
            err,
            err?.response?.data?.detail ?? this.$t("Failed to update assignment status"),
          );
        });
    },

    loadAssignments(ctx, callback) {
      SecondmentService.get({
        fields: 'id,country,created,date_start,date_end,clients,employees,contractors,days,status,past,started',
        page: ctx.currentPage,
        perPage: ctx.perPage,
        sortBy: ctx.sortBy || "created",
        sortDesc: ctx.sortDesc,
        search: ctx.filter
      }).then(response => {
        this.total = response.data.count;
        callback(response.data.results);
      }).catch(err => {
        callback([]);
        console.error('@loadAssignments', err);
      });
    },

    calculateDays(start, end) {
      let days = DateService.diff(end, start);
      return days + 1;
    }
  },
};
</script>

<style scoped lang="scss">
.employees-dropdown {
  font-size: 12px;
  text-transform: capitalize;
  text-decoration: underline;
  color: var(--primary);
}

.text-capitalize {
  text-transform: capitalize;
}

::v-deep .fixed-width {
  max-width: 13ch;
  flex-grow: 0;
}
</style>
